import React from "react";
import { Row, Col, Label, Input, FormGroup } from "reactstrap";
import { NumericFormat } from "react-number-format";

const RetiradaConta = ({ token, tokens, moeda, setMoeda, moedas, amount, setAmount, contaDestino, setContaDestino, contas }) => {
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label>Token</Label>
          <Input
            type="select"
            value={token || ""}
            disabled={true}
          >
            <option value="">Seleccionar Token</option>
            {tokens.map((t) => (
              <option key={t.id} value={t.id}>
                {t.sigla} - ({t.rede.nome})
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Moneda</Label>
          <Input
            type="select"
            value={moeda || ""}
            onChange={(e) => setMoeda(e.target.value)}
          >
            <option value="">Seleccionar Moneda</option>
            {moedas.map((m) => (
              <option key={m.id} value={m.id}>
                {m.sigla}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Monto (USD)</Label>
          <NumericFormat
            value={amount}
            onValueChange={(values) => setAmount(values.value)}
            className="form-control"
            placeholder="Monto en USD"
            allowNegative={false}
            decimalScale={2}
            decimalSeparator="."
            thousandSeparator=","
          />
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Cuenta de Destino</Label>
          <Input
            type="select"
            value={contaDestino}
            onChange={(e) => setContaDestino(e.target.value)}
          >
            <option value="">Seleccionar Cuenta</option>
            {contas.map((c) => (
              <option key={c.id} value={c.id}>
                {c.banco} - {c.nome}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RetiradaConta;
