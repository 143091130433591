import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { NumericFormat } from "react-number-format";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, ordem }) => {
  const [loading, setLoading] = useState(false);
  const [statusOrdem, setStatusOrdem] = useState("");
  const [userDados, setUserDados] = useState(null);

  const toggle = () => {
    setStatusOrdem("");
    setModal(!modal);
  };

  const editar = async (ordem) => {
    setLoading(true);

    const data = {
      status: statusOrdem,
      ordem_id: ordem.id,
    };

    const response = await apiClient.put(`/ordens-pix`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchOrdem = async (ordem) => {
      const userData = await getLoggedinUser();
      setUserDados(userData);
      setStatusOrdem(ordem.status);
      console.log(ordem);
    };
    if (ordem) {
      fetchOrdem(ordem);
    }
  }, [ordem]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Orden #{ordem?.id}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <Label for="usuario">Usuario</Label>
              <Input
                type="text"
                name="usuario"
                id="usuario"
                value={ordem?.user?.nome}
                disabled
              />
            </Col>
            <Col md="6">
              <Label for="usuario">Tipo</Label>
              <Input
                type="text"
                name="usuario"
                id="usuario"
                value={
                  ordem?.user?.tipo === "admin"
                    ? "Administrador"
                    : ordem?.user?.tipo === "lojista"
                    ? "Comerciante"
                    : "Atendente"
                }
                disabled
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="entrada-brl">Entrada BRL</Label>
              <NumericFormat
                id="entrada-brl"
                placeholder="R$ 0,00"
                className="form-control"
                value={ordem?.total_brl || 0}
                onValueChange={() => {}}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                fixedDecimalScale={true}
                allowNegative={false}
                disabled
              />
            </Col>
            <Col md="6">
              <Label for="cotacao">Cotización USD</Label>
              <NumericFormat
                id="cotacao"
                placeholder="R$ 0,00"
                className="form-control"
                value={ordem?.cotacao_nautt || 0}
                onValueChange={() => {}}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                prefix="R$ "
                fixedDecimalScale={true}
                allowNegative={false}
                disabled
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <Label for="usd-cliente">Valor USD Cliente</Label>
              <NumericFormat
                id="usd-cliente"
                placeholder="R$ 0,00"
                className="form-control"
                value={ordem?.total_token || 0}
                onValueChange={() => {}}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={3}
                prefix="$ "
                fixedDecimalScale={true}
                allowNegative={false}
                disabled
              />
            </Col>
            <Col md="6">
              <Label for="cotacao">Ganancia USD</Label>
              <NumericFormat
                id="cotacao"
                placeholder="R$ 0,00"
                className="form-control"
                value={ordem?.lucro || 0}
                onValueChange={() => {}}
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={3}
                prefix="$ "
                fixedDecimalScale={true}
                allowNegative={false}
                disabled
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <Label for="status-ordem">Estado</Label>
              <Input
                type="select"
                name="status-ordem"
                id="status-ordem"
                value={statusOrdem}
                onChange={(e) => setStatusOrdem(e.target.value)}
                disabled={userDados?.tipo !== "admin"}
              >
                <option value="novo">Nuevo</option>
                <option value="pago">Pagado</option>
                <option value="enviado">Enviado</option>
                <option value="invalido">Inválido</option>
                <option value="cancelado">Cancelado</option>
                <option value="processando">Procesando</option>
                <option value="finalizada">Finalizado</option>
                <option value="reembolsado">Reembolsado</option>
              </Input>
              <small className="text-muted">
                Si se marca como <b>Finalizado</b>, el sistema acreditará el
                valor en la wallet del cliente.
              </small>
              <br />
              <small className="text-muted">
                Si se marca como <b>Reembolsado</b>, el sistema retirará el
                valor de la wallet del cliente, a menos que ya haya sido
                enviado.
              </small>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-success fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            {userDados?.tipo === "admin" && (
              <button
                className="btn btn-success"
                onClick={() => editar(ordem)}
                disabled={loading}
              >
                Editar
              </button>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
