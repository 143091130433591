import React from "react";
import { Col, Row } from "reactstrap";

const Section = ({ nauttPontos, userData }) => {
  const getGreeting = () => {
    const hour = new Date().getHours();
    if (hour >= 5 && hour < 12) return "¡Buenos días";
    if (hour >= 12 && hour < 19) return "¡Buenas tardes";
    return "¡Buenas noches";
  };

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="flex-grow-1">
              <h4 className="fs-16 mb-1">{getGreeting()}, {userData.name}!</h4>
              <p className="text-muted mb-0">
                Maximice sus ganancias y simplifique sus pagos con nuestra plataforma integral
              </p>
            </div>
            <div className="mt-3 mt-lg-0">
              <form action="#">
                <Row className="g-3 mb-0 align-items-center">
                  <div className="col-auto">
                    <button type="button" className="btn btn-soft-warning">
                      <i className="ri-trophy-line align-middle me-1"></i>{" "}
                      {nauttPontos} Nautt Puntos
                    </button>
                  </div>
                </Row>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Section;
