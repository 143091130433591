import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import { NumericFormat } from "react-number-format";

const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, retirada, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [cotacao, setCotacao] = useState("");
  const [amountFinal, setAmountFinal] = useState("");
  const [lucroNautt, setLucroNautt] = useState("");

  const toggle = () => {
    setStatus("");
    setCotacao("");
    setAmountFinal("");
    setLucroNautt("");
    setModal(!modal);
  };

  const isReadOnly = ['finalizada', 'cancelada'].includes(retirada?.status);

  const editar = async () => {
    if (isReadOnly) return;
    
    setLoading(true);

    const data = {
      status: status,
      retirada_id: retirada.id
    };

    if (retirada?.converter) {
      data.cotacao = parseFloat(cotacao);
      data.amount_final = parseFloat(amountFinal);
      data.lucro_nautt = parseFloat(lucroNautt);
    }

    const response = await apiClient.put(`/retiradas`, data);
  
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDados = async () => {
      if (retirada?.converter) {
        setCotacao(retirada.cotacao);
        setAmountFinal(retirada.amount_final);
        setLucroNautt(retirada.lucro_nautt);
        setStatus(retirada.status);
      }
    };
    console.log(retirada);
    fetchDados();
  }, [retirada, modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        {isReadOnly ? "Ver Retiro" : "Editar Retiro"}
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Token</Label>
              <Input
                type="text"
                value={`${retirada?.token?.sigla} (${retirada?.token?.rede?.nome})` || ""}
                disabled
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Monto Original (USD)</Label>
              <NumericFormat
                value={retirada?.amount || ""}
                className="form-control"
                disabled
                allowNegative={false}
                decimalScale={2}
                decimalSeparator="."
                thousandSeparator=","
              />
            </FormGroup>
          </Col>
          {retirada?.converter ? (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Cotización</Label>
                  <NumericFormat
                    value={cotacao}
                    onValueChange={(values) => setCotacao(values.value)}
                    className="form-control"
                    disabled={isReadOnly}
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Por Cobrar</Label>
                  <NumericFormat
                    value={amountFinal}
                    onValueChange={(values) => setAmountFinal(values.value)}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Tasa (USD)</Label>
                  <NumericFormat
                    value={lucroNautt}
                    onValueChange={(values) => setLucroNautt(values.value)}
                    className="form-control"
                    disabled={isReadOnly}
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Moneda</Label>
                  <Input
                    type="text"
                    value={`${retirada?.moeda?.nome} (${retirada?.moeda?.sigla}) - ${retirada?.moeda?.pais}`}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Cuenta de Destino</Label>
                  <Input
                    type="text"
                    value={`${retirada?.conta?.nome} - ${retirada?.conta?.banco} - Ag: ${retirada?.conta?.agencia} Cuenta: ${retirada?.conta?.conta}`}
                    disabled
                  />
                </FormGroup>
              </Col>
            </>
          ) : (
            <>
              <Col md={6}>
                <FormGroup>
                  <Label>Por Cobrar (USD)</Label>
                  <NumericFormat
                    value={retirada?.amount_final || ""}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Tasa (USD)</Label>
                  <NumericFormat
                    value={retirada?.lucro_nautt || ""}
                    className="form-control"
                    disabled
                    allowNegative={false}
                    decimalScale={2}
                    decimalSeparator="."
                    thousandSeparator=","
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label>Wallet Cripto</Label>
                  <Input
                    type="text"
                    value={retirada?.carteira?.carteira || ""}
                    disabled
                  />
                </FormGroup>
              </Col>
            </>
          )}
          <Col md={6}>
            <FormGroup>
              <Label>Estado</Label>
              <Input
                type="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                disabled={isReadOnly}
              >
                <option value="">Seleccionar Estado</option>
                <option value="nova">Nueva</option>
                <option value="processando">Procesando</option>
                <option value="finalizada">Finalizada</option>
                <option value="cancelada">Cancelada</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>

        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-primary fw-medium"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Cerrar
          </Link>
          {!isReadOnly && (
            <button
              className="btn btn-primary"
              onClick={() => editar()}
              disabled={loading}
            >
              Guardar
            </button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
