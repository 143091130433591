import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import classnames from "classnames";
import RetiradaCripto from "./RetiradaCripto";
import RetiradaConta from "./RetiradaConta";

const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [moeda, setMoeda] = useState(null);
  const [moedas, setMoedas] = useState([]);
  const [token, setToken] = useState(null);
  const [tokens, setTokens] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [amount, setAmount] = useState("");
  const [contaDestino, setContaDestino] = useState("");
  const [carteiraId, setCarteiraId] = useState("");
  const [contas, setContas] = useState([]);
  const [carteiras, setCarteiras] = useState([]);

  const toggle = () => {
    setMoeda(null);
    setMoedas([]);
    setToken(null);
    setAmount("");
    setContaDestino("");
    setCarteiraId("");
    setModal(!modal);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const fetchMoedas = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;
      setMoedas(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const fetchTokens = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/tokens");
    if (response.sucesso) {
      const dados = response.dados;
      setTokens(dados);
      // Set USDT token as default
      const usdtToken = dados.find(
        (t) => t.sigla === "USDT" && t.rede.nome === "Arbitrum"
      );
      if (usdtToken) setToken(usdtToken.id);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const fetchContas = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/contas-cliente");
    if (response.sucesso) {
      setContas(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const fetchCarteiras = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/carteira-cliente");
    if (response.sucesso) {
      setCarteiras(response.dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  }, []);

  const criar = async () => {
    setLoading(true);

    // Validate minimum amounts
    const amountNum = parseFloat(amount);
    if (activeTab === "1" && amountNum < 10) {
      toast.error("El monto mínimo para retiro es USD 10");
      setLoading(false);
      return;
    }
    if (activeTab === "2" && amountNum < 5) {
      toast.error("El monto mínimo para retiro cripto es USD 5");
      setLoading(false);
      return;
    }

    const data = {
      token_id: token,
      amount: amountNum,
      converter: activeTab === "1",
    };

    if (activeTab === "1") {
      if (!contaDestino) {
        toast.error("Seleccione una cuenta de destino");
        setLoading(false);
        return;
      }
      if (!moeda) {
        toast.error("Seleccione una moneda");
        setLoading(false);
        return;
      }
      data.conta_destino = contaDestino;
      data.moeda_id = moeda;
    } else {
      if (!carteiraId) {
        toast.error("Seleccione una wallet cripto");
        setLoading(false);
        return;
      }
      data.carteira_id = carteiraId;
    }

    const response = await apiClient.post(`/retiradas`, data);

    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchMoedas(),
        fetchTokens(),
        fetchContas(),
        fetchCarteiras(),
      ]);
    };
    if (modal) fetchData();
  }, [modal]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nuevo Retiro
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              Convertir
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Retiro Cripto
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="mt-4">
          <TabPane tabId="1">
            {contas.length === 0 ? (
              <div className="alert alert-warning">
                Necesita registrar una cuenta bancaria primero.{" "}
                <Link to="/profile">Ir al perfil</Link>
              </div>
            ) : (
              <RetiradaConta
                token={token}
                tokens={tokens}
                moeda={moeda}
                setMoeda={setMoeda}
                moedas={moedas}
                amount={amount}
                setAmount={setAmount}
                contaDestino={contaDestino}
                setContaDestino={setContaDestino}
                contas={contas}
              />
            )}
          </TabPane>

          <TabPane tabId="2">
            {carteiras.length === 0 ? (
              <div className="alert alert-warning">
                Necesita registrar una wallet cripto primero.{" "}
                <Link to="/profile">Ir al perfil</Link>
              </div>
            ) : (
              <RetiradaCripto
                token={token}
                tokens={tokens}
                amount={amount}
                setAmount={setAmount}
                carteiraId={carteiraId}
                setCarteiraId={setCarteiraId}
                carteiras={carteiras}
              />
            )}
          </TabPane>
        </TabContent>

        <div className="hstack gap-2 justify-content-end mt-5">
          <Link
            to="#"
            className="btn btn-link link-success fw-medium"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Cerrar
          </Link>
          <button
            className="btn btn-success"
            onClick={() => criar()}
            disabled={loading}
          >
            Crear Retiro
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
