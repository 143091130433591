import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Spinner,
  Tooltip,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient, getLoggedinUser } from "../../helpers/api_helper";
import InputMask from "react-input-mask";
import { formatEmail } from "../../Components/FunctionsRepository";
import CarteiraClienteTable from "./CarteiraClienteTable";
import ContaClienteTable from "./ContaClienteTable";
import TwoFactorAuth from "./2FA";
import ModalMudarSenha from "../Usuarios/ModalMudarSenha";
import FeatherIcon from "feather-icons-react";

import avatar from "../../assets/images/logo-sm-1.png";

const UserProfile = () => {
  document.title = "Perfil del Usuario | Nautt Finance";

  const [userData, setUserData] = useState({
    id: "",
    nome: "",
    username: "",
    email: "",
    telefone: "",
    pais_telefone: "BR",
    login_2fa: false,
  });

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [modal, setModal] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const toggleModal = () => {
    setModal(!modal);
  };

  const fetchUserData = async (userId) => {
    setLoading(true);
    const apiClient = new APIClient();
    const response = await apiClient.get("/usuarios", { user_id: userId });
    setLoading(false);

    if (response.sucesso) {
      const user = response.dados;
      setUserData({
        id: user.id,
        nome: user.nome,
        username: user.username,
        email: user.email,
        telefone: user.telefone || "",
        pais_telefone: user.pais_telefone || "BR",
        login_2fa: user.login_2fa || false,
      });
    } else {
      toast.error(
        response.mensagem || "Error al cargar los datos del usuario."
      );
    }
  };

  useEffect(() => {
    const initializeUserData = async () => {
      const loggedInUser = await getLoggedinUser();
      if (loggedInUser && loggedInUser.id) {
        fetchUserData(loggedInUser.id);
      }
    };

    initializeUserData();
  }, [refresh]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "email") {
      setUserData({
        ...userData,
        [name]: formatEmail(value),
      });
    } else if (type === "checkbox") {
      setUserData({
        ...userData,
        [name]: checked,
      });
    } else {
      setUserData({
        ...userData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const apiClient = new APIClient();
    const response = await apiClient.put("/usuarios", {
      user_id: userData.id,
      email: userData.email,
      telefone: userData.telefone.replace(/\D/g, ""),
      pais_telefone: userData.pais_telefone,
      login_2fa: userData.login_2fa,
    });

    setLoading(false);

    if (response.sucesso) {
      toast.success("¡Perfil actualizado con éxito!");
    } else {
      toast.error(response.mensagem || "Error al actualizar el perfil.");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="mx-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{userData.nome}</h5>
                        <p className="mb-1">
                          Correo electrónico: {userData.email}
                        </p>
                        <p className="mb-0">ID: #{userData.id}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col xl="8" lg="8" xs="12">
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Editar Perfil</h4>
                  <div className="flex-shrink-0">
                    <Button color="soft-warning" onClick={() => toggleModal()}>
                      <FeatherIcon icon="key" width="12" className="me-2" />
                      Cambiar contraseña
                    </Button>
                  </div>
                </CardHeader>
                <CardBody>
                  <Form className="form-horizontal" onSubmit={handleSubmit}>
                    <Row className="mb-3">
                      <Col md="6">
                        <Label className="form-label">Nombre de Usuario</Label>
                        <Input
                          name="username"
                          className="form-control"
                          placeholder="Nombre de Usuario"
                          type="text"
                          value={userData.username}
                          readOnly
                        />
                      </Col>
                      <Col md="6">
                        <Label className="form-label">Correo electrónico</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Correo electrónico"
                          type="email"
                          value={userData.email}
                          onChange={handleInputChange}
                          maxLength={150}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <Label className="form-label">País del Teléfono</Label>
                        <Input
                          type="select"
                          name="pais_telefone"
                          value={userData.pais_telefone}
                          onChange={handleInputChange}
                          className="form-control"
                        >
                          <option value="BR">Brasil (+55)</option>
                          <option value="AR">Argentina (+54)</option>
                        </Input>
                      </Col>
                      <Col md="6">
                        <Label className="form-label">Teléfono</Label>
                        <InputMask
                          mask={
                            userData.pais_telefone === "BR"
                              ? "(99) 99999-9999"
                              : "999 999-9999"
                          }
                          placeholder={
                            userData.pais_telefone === "BR"
                              ? "(99) 99999-9999"
                              : "999 999-9999"
                          }
                          value={userData.telefone}
                          onChange={handleInputChange}
                        >
                          {(inputProps) => (
                            <Input
                              type="text"
                              name="telefone"
                              className="form-control"
                              {...inputProps}
                            />
                          )}
                        </InputMask>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <div className="form-check mb-2">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="withdrawalCheck"
                            checked={true}
                            disabled
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="withdrawalCheck"
                            id="withdrawalTooltip"
                          >
                            ¿2FA para retiros?
                          </Label>
                          <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target="withdrawalTooltip"
                            toggle={toggleTooltip}
                          >
                            No se puede deshabilitar la autenticación 2FA para
                            retiros
                          </Tooltip>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="form-check mb-2">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id="login2faCheck"
                            name="login_2fa"
                            checked={userData.login_2fa}
                            onChange={handleInputChange}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="login2faCheck"
                          >
                            ¿2FA para inicio de sesión?
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center mt-4">
                      <Button type="submit" color="primary" disabled={loading}>
                        {loading ? <Spinner size="sm" /> : "Actualizar Perfil"}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <ModalMudarSenha
                modal={modal}
                setModal={setModal}
                setRefresh={setRefresh}
                usuario={userData}
              />
            </Col>
            <Col xl="4" lg="4" xs="12">
              <TwoFactorAuth userId={userData.id} />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col lg="6">
              <CarteiraClienteTable userId={userData.id} />
            </Col>
            <Col lg="6">
              <ContaClienteTable userId={userData.id} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserProfile;
