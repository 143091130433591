// Função para verificar o último horário de login
export const checkLastLogin = async () => {
  const lastLoginTimestamp = localStorage.getItem("lastLogin");
  if (lastLoginTimestamp) {
    const lastLoginTime = new Date(parseInt(lastLoginTimestamp));
    const currentTime = new Date();
    const timeDiffInHours = (currentTime - lastLoginTime) / (1000 * 60 * 60);

    if (timeDiffInHours >= 1) {
      localStorage.removeItem("lastLogin");
      localStorage.removeItem("authUser");
      window.location.reload();
    }
  }
};

export const converterData = (dataISO, minutoSegundo = true) => {
  if (dataISO) {
    const ano = dataISO.substring(0, 4);
    const mes = dataISO.substring(5, 7);
    const dia = dataISO.substring(8, 10);
    const hora = dataISO.substring(11, 13);
    const minuto = dataISO.substring(14, 16);

    return minutoSegundo
      ? `${dia}/${mes}/${ano} ${hora}:${minuto}`
      : `${dia}/${mes}/${ano}`;
  }
  return "";
};

export const generateRandomString = () => {
  const letras = "abcdefghijklmnopqrstuvwxyz";
  const numeros = "0123456789";

  let resultado = "";
  for (let i = 0; i < 4; i++) {
    resultado += letras.charAt(Math.floor(Math.random() * letras.length));
  }

  for (let i = 0; i < 4; i++) {
    resultado += numeros.charAt(Math.floor(Math.random() * numeros.length));
  }

  return resultado;
};

export const formatCurrency = (value) => {
  if (value === 0) {
    return "R$ 0,00";
  }
  if (!value) {
    return "";
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export const formatCurrencyUSD = (value) => {
  if (value === 0) {
    return "$ 0,00";
  }
  if (!value) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
}

export const formatCurrencyARS = (value) => {
  if (value === 0) {
    return "0,00 ARS";
  }
  if (!value) {
    return "";
  }
  return new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "ARS",
  }).format(value);
}

export const formatEmail = (value) => {
  value = value.replace(/[^a-zA-Z0-9.\-_@]+/g, "");

  const atSymbolCount = value.split("@").length - 1;
  if (atSymbolCount > 1) {
    const index = value.lastIndexOf("@");
    value = value.slice(0, index) + value.slice(index + 1);
  }

  return value;
};

export const nomeMask = (nome) => {
  const particulas = [
    "e",
    "de",
    "do",
    "da",
    "dos",
    "das",
    "no",
    "na",
    "nos",
    "nas",
  ];

  const nomeProcessado = nome.replace(/\d/g, "").replace(/\s+/g, " ").trim();

  const palavras = nomeProcessado.split(" ");

  const nomeFormatado = palavras.map((palavra, index) => {
    palavra = palavra.toLowerCase();

    if (particulas.includes(palavra) && index !== 0) {
      return palavra;
    }

    return palavra.charAt(0).toUpperCase() + palavra.slice(1);
  });

  return nomeFormatado.join(" ");
};

// Função de aplicar máscara de telefone com DDD para fixo ou móvel
export const telefoneMask = (telefone) => {
  if (!telefone) return "";
  telefone = telefone.replace(/\D/g, "");
  
  if (telefone.length === 11) {
    telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  } else if (telefone.length === 10) {
    telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
  }
  
  return telefone;
};

//funcao de aplicar mascara de cpf
export const cpfMask = (cpf) => {
  if (!cpf) return "";
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return cpf;
};

//funcao de aplicar mascara de cnpj
export const cnpjMask = (cnpj) => {
  if (!cnpj) return "";
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
  cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
  return cnpj;
};


export const trataUsename = (input) => {
  const resultado = input
    .replace(/[^A-Za-z0-9_]/g, "")
    .replace(/\s/g, "")
    .toLowerCase()
    .slice(0, 15);
  return resultado;
};
