import React from "react";
import { Row, Col, Label, Input, FormGroup, UncontrolledTooltip } from "reactstrap";
import { NumericFormat } from "react-number-format";

const RetiradaCripto = ({ token, tokens, amount, setAmount, carteiraId, setCarteiraId, carteiras }) => {
  return (
    <Row>
      <Col md={6}>
        <FormGroup>
          <Label>Token</Label>
          <Input
            type="select"
            value={token || ""}
            disabled={true}
            id="tokenSelect"
          >
            <option value="">Seleccionar Token</option>
            {tokens.map((t) => (
              <option key={t.id} value={t.id}>
                {t.sigla} - ({t.rede.nome})
              </option>
            ))}
          </Input>
          <UncontrolledTooltip placement="top" target="tokenSelect">
            Solo retiros en USDT por el momento
          </UncontrolledTooltip>
        </FormGroup>
      </Col>
      <Col md={6}>
        <FormGroup>
          <Label>Monto (USD)</Label>
          <NumericFormat
            value={amount}
            onValueChange={(values) => setAmount(values.value)}
            className="form-control"
            placeholder="Monto en USD"
            allowNegative={false}
            decimalScale={2}
            decimalSeparator="."
            thousandSeparator=","
          />
          <small className="text-muted">Tasa de retiro: USD 0.50</small>
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label>Wallet Cripto</Label>
          <Input
            type="select"
            value={carteiraId}
            onChange={(e) => setCarteiraId(e.target.value)}
          >
            <option value="">Seleccionar Wallet</option>
            {carteiras.map((c) => (
              <option key={c.id} value={c.id}>
                {c.nome} - {c.carteira}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default RetiradaCripto;
