import React from "react";
import { Col } from "reactstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";

// Import Images
import logoLight from "../../assets/images/logo-light.png";
import bgOnboarding from "../../assets/images/onboarding-bg.jpg";

const AuthSlider = () => {
  return (
    <React.Fragment>
      <Col lg={6}>
        <div
          className="p-lg-5 p-4 auth-one-bg h-100"
          style={{
            background: `url(${bgOnboarding})`,
          }}
        >
          <div
            className="bg-overlay"
            style={{
              background: "none",
            }}
          ></div>
          <div className="position-relative h-100 d-flex flex-column">
            <div className="mb-4">
              <Link to="https://nautt.finance/" className="d-block">
                <img src={logoLight} alt="" height="30" />
              </Link>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

export default AuthSlider;
