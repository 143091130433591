import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardText,
  Col,
  Container,
  Row,
  FormFeedback,
  Input,
  Button,
  Form,
} from "reactstrap";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import Footer from "../../../Layouts/Footer";
import AuthSlider from "../authCarousel";
import FeatherIcon from "feather-icons-react";
import { APIClient } from "../../../helpers/api_helper";

const CoverSignUp = () => {
  document.title = "Registro | Nautt Finance";

  const [formData, setFormData] = useState({
    nome: "",
    empresa: "",
    pais_telefone: "",
    telefone: "",
    email: "",
  });

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value.replace(/[^\d]/g, "") });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const apiClient = new APIClient();
      const response = await apiClient.post("/onboardings", {
        ...formData,
        telefone: formData.telefone.replace(/\D/g, ""),
      });

      if (response.sucesso) {
        toast.success("Registro exitoso");
        setFinished(true);
      } else {
        setError(response.mensagem || "Error en el registro");
      }
    } catch (err) {
      setError(
        "Error en el servidor. Por favor, inténtelo de nuevo más tarde."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden m-0">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-secondary">Onboarding</h5>
                          <p className="text-muted">
                            Obtenga su cuenta gratuita de Nautt Finance ahora.
                          </p>
                        </div>

                        {finished ? (
                          <CardBody className="p-4">
                            <div
                              className={`rounded-circle p-3 mb-2 bg-success`}
                              style={{
                                width: "100px",
                                height: "100px",
                                margin: "0 auto",
                              }}
                            >
                              <FeatherIcon
                                icon="check-circle"
                                className="text-white"
                                size={70}
                              />
                            </div>
                            <CardText
                              tag="h4 text-center"
                              className="font-weight-bold mt-4"
                            >
                              ¡Sus datos han sido enviados con éxito! Espere el
                              contacto de nuestro equipo.
                            </CardText>
                          </CardBody>
                        ) : (
                          <div className="mt-4">
                            <Form
                              className="needs-validation"
                              noValidate
                              onSubmit={handleSubmit}
                            >
                              <div className="mb-3">
                                <label htmlFor="nome" className="form-label">
                                  Nombre <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nome"
                                  placeholder="Ingrese su nombre"
                                  name="nome"
                                  value={formData.nome}
                                  onChange={handleChange}
                                  required
                                />
                                <FormFeedback>
                                  Por favor, ingrese su nombre
                                </FormFeedback>
                              </div>

                              <div className="mb-3">
                                <label htmlFor="empresa" className="form-label">
                                  Empresa <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="empresa"
                                  placeholder="Ingrese el nombre de su empresa"
                                  name="empresa"
                                  value={formData.empresa}
                                  onChange={handleChange}
                                  required
                                />
                                <FormFeedback>
                                  Por favor, ingrese el nombre de su empresa
                                </FormFeedback>
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="pais_telefone"
                                  className="form-label"
                                >
                                  País del Teléfono{" "}
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="pais_telefone"
                                  placeholder="Ingrese lo país del teléfono"
                                  name="pais_telefone"
                                  value={formData.pais_telefone}
                                  onChange={handleChange}
                                />
                                {/* <Input
                                  type="select"
                                  className="form-control"
                                  id="pais_telefone"
                                  name="pais_telefone"
                                  value={formData.pais_telefone}
                                  onChange={handleChange}
                                >
                                  <option value="Brasil">Brasil</option>
                                  <option value="Argentina">Argentina</option>
                                </Input> */}
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="telefone"
                                  className="form-label"
                                >
                                  Teléfono
                                </label>
                                {/* <InputMask
                                  mask={
                                    formData.pais_telefone === "BR"
                                      ? "+55 (99) 99999-9999"
                                      : "+54 (99) 9999-9999"
                                  }
                                  value={formData.telefone}
                                  onChange={handlePhoneChange}
                                >
                                  {(inputProps) => (
                                    <Input
                                      {...inputProps}
                                      type="text"
                                      className="form-control"
                                      id="telefone"
                                      name="telefone"
                                      placeholder={
                                        formData.pais_telefone === "BR"
                                          ? "+55 (11) 98765-4321"
                                          : "+54 (11) 1234-5678"
                                      }
                                    />
                                  )}
                                </InputMask> */}
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="telefone"
                                  placeholder="Ingrese su teléfono"
                                  name="telefone"
                                  value={formData.telefone}
                                  onChange={handleChange}
                                />
                              </div>

                              <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                  Correo electrónico{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Ingrese su correo electrónico"
                                  name="email"
                                  value={formData.email}
                                  onChange={handleChange}
                                  required
                                />
                                <FormFeedback>
                                  Por favor, ingrese su correo electrónico
                                </FormFeedback>
                              </div>

                              <div className="mb-4">
                                <p className="mb-0 fs-12 text-muted fst-italic">
                                  Al registrarse, usted acepta los{" "}
                                  <Link
                                    to="#"
                                    className="text-primary text-decoration-underline fst-normal fw-medium"
                                  >
                                    Términos de Uso
                                  </Link>{" "}
                                  de Nautt Finance
                                </p>
                              </div>

                              {error && (
                                <div className="alert alert-danger">
                                  {error}
                                </div>
                              )}

                              <div className="mt-4">
                                <Button
                                  color="success"
                                  className="w-100"
                                  type="submit"
                                  disabled={loading}
                                >
                                  {loading ? "Registrando..." : "Registrarse"}
                                </Button>
                              </div>
                            </Form>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default CoverSignUp;
