import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import Calculadora from "./Calculadora";

const GerarOrdemPix = () => {
  document.title = "Gerar Ordem de Pagamento | Nautt Finance";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Row className="justify-content-center">
                  <Col xs="12" sm="12" md="8" lg="8" xl="6" xxl="6">
                    <Calculadora />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GerarOrdemPix;
