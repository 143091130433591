import flagus from "../assets/images/flags/us.svg";
import flagspain from "../assets/images/flags/spain.svg";
import flagsbrazil from "../assets/images/flags/br.svg";

const languages = {
  sp: {
    label: "Español",
    flag: flagspain,
  },
  br: {
    label: "Português",
    flag: flagsbrazil,
  },
  en: {
    label: "English",
    flag: flagus,
  },
}

export default languages
