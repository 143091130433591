import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh, pdvs }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [pdvsIds, setPdvsIds] = useState([]);

  const toggle = () => {
    setNome("");
    setPdvsIds([]);
    setModal(!modal);
  };

  const handlePdvChange = (pdvId) => {
    setPdvsIds((prevIds) => {
      if (prevIds.includes(pdvId)) {
        return prevIds.filter((id) => id !== pdvId);
      } else {
        return [...prevIds, pdvId];
      }
    });
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      nome: nome,
      pdvs_ids: pdvsIds,
    };

    const response = await apiClient.post(`/atendentes`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Novo Atendente
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nombre
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nombre"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                  maxLength={50}
                />
              </div>
            </Col>
            <Col md="6">
              <Label className="form-label">PDVs</Label>
              <div
                className="pdv-checkboxes"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                  {pdvs.map((pdv) => (
                    <Col md="6">
                      <FormGroup check key={pdv.id} className="mb-2">
                        <Input
                          type="checkbox"
                          id={`pdv-${pdv.id}`}
                          checked={pdvsIds.includes(pdv.id)}
                          onChange={() => handlePdvChange(pdv.id)}
                        />
                        <Label check for={`pdv-${pdv.id}`}>
                          {pdv.nome}
                        </Label>
                      </FormGroup>
                    </Col>
                  ))}
              </div>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
