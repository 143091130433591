import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import Select from "react-select";
import { NumericFormat } from "react-number-format";
const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [ordem, setServico] = useState("");
  const [descricao, setDescricao] = useState("");
  const [tipoFilial, setTipoFilial] = useState("");
  const [preco, setPreco] = useState("");

  const toggle = () => {
    setServico("");
    setDescricao("");
    setTipoFilial("");
    setPreco("");

    setModal(!modal);
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      ordem,
      descricao,
      preco: preco==="" ? 0 : preco,
      tipo_filial: tipoFilial
    };

    const response = await apiClient.post("/ordems", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nuevo Servicio
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="ordem" className="form-label">
                  Servicio <span className="text-danger">*</span>
                </Label>
                <Input
                  id="ordem"
                  className="form-control"
                  type="text"
                  placeholder="Servicio"
                  value={ordem}
                  onChange={(e) => setServico(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="descricao" className="form-label">
                  Descripción
                </Label>
                <Input
                  id="descricao"
                  className="form-control"
                  type="textarea"
                  placeholder="Descripción"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  maxLength={255}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="tipoFilial" className="form-label">
                  Tipo de Sucursal <span className="text-danger">*</span>
                </Label>
                <Input
                  id="tipoFilial"
                  className="form-control"
                  type="select"
                  placeholder="Tipo de Sucursal"
                  value={tipoFilial}
                  onChange={(e) => setTipoFilial(e.target.value)}
                >
                  <option value="">Seleccione</option>
                  <option value="propria">Propia</option>
                  <option value="terceirizada">Tercerizada</option>
                </Input>
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="preco" className="form-label">
                  Precio <span className="text-danger">*</span>
                </Label>
                <NumericFormat
                  id="preco"
                  placeholder="R$ 0,00"
                  className="form-control"
                  value={preco}
                  onValueChange={({ value }) => setPreco(value)}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  prefix="R$ "
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
          </Row>
          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-secondary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-secondary"
              onClick={() => criar()}
              disabled={loading}
            >
              Crear
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
