import React from "react";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import CheckoutProdutos from "./CheckoutProdutos";
import Pdv from "./Pdv";

const Home = () => {
  const { pdv_id, link_id } = useParams();

  return (
    <React.Fragment>
      <Container>
        <Row className="justify-content-center">
          {pdv_id ? <Pdv pdv_id={pdv_id} /> : <CheckoutProdutos link_id={link_id} />}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Home;
