import React, { useState, useEffect, useCallback } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { NumericFormat } from "react-number-format";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalEditar = ({ modal, setModal, setRefresh, produto }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [valor, setValor] = useState("");
  const [descricao, setDescricao] = useState("");
  const [moedas, setMoedas] = useState([]);
  const [moeda, setMoeda] = useState("");
  const [currencyPrefix, setCurrencyPrefix] = useState("$ ");
  const [currencySuffix, setCurrencySuffix] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const toggle = () => {
    setNome("");
    setValor("");
    setDescricao("");
    setMoeda("");
    setSelectedCurrency(null);
    setCurrencyPrefix("$ ");
    setCurrencySuffix("");
    setModal(!modal);
  };

  const fetchMoedas = useCallback(async () => {
    setLoading(true);
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      const dados = response.dados;
      setMoedas(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const editar = async (produto) => {
    setLoading(true);

    const data = {
      nome,
      valor,
      descricao,
      moeda_id: moeda,
      produto_id: produto.id,
    };

    const response = await apiClient.put(`/produtos`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchMoedas();
    };
    if (modal) {
      fetchData();
      setNome(produto.nome);
      setValor(produto.valor);
      setDescricao(produto.descricao);
      setMoeda(produto.moeda_id || "");
    }
  }, [modal]);

  // Handle currency selection
  useEffect(() => {
    if (moeda && moedas.length > 0) {
      const currency = moedas.find((m) => m.id.toString() === moeda.toString());
      setSelectedCurrency(currency);
    } else {
      setSelectedCurrency(null);
    }
  }, [moeda, moedas]);

  // Handle currency format updates
  useEffect(() => {
    if (selectedCurrency) {
      switch (selectedCurrency.sigla) {
        case "BRL":
          setCurrencyPrefix("R$ ");
          setCurrencySuffix("");
          break;
        case "ARS":
          setCurrencyPrefix("");
          setCurrencySuffix(" ARS");
          break;
        default:
          setCurrencyPrefix("$ ");
          setCurrencySuffix("");
      }
    } else {
      setCurrencyPrefix("$ ");
      setCurrencySuffix("");
    }
  }, [selectedCurrency]);

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Editar Produto
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nombre
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nombre"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                  maxLength={50}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="valor" className="form-label">
                  Valor
                </Label>
                <NumericFormat
                  className="form-control"
                  placeholder={`${currencyPrefix}0${
                    selectedCurrency?.sigla === "BRL" ? "," : "."
                  }00${currencySuffix}`}
                  value={valor}
                  onValueChange={({ value }) => setValor(parseFloat(value))}
                  thousandSeparator={
                    selectedCurrency?.sigla === "BRL" ? "." : ","
                  }
                  decimalSeparator={
                    selectedCurrency?.sigla === "BRL" ? "," : "."
                  }
                  decimalScale={2}
                  prefix={currencyPrefix}
                  suffix={currencySuffix}
                  fixedDecimalScale={true}
                  allowNegative={false}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="moeda" className="form-label">
                  Moneda
                </Label>
                <Input
                  id="moeda"
                  className="form-control"
                  type="select"
                  value={moeda}
                  onChange={(e) => {
                    setMoeda(e.target.value);
                  }}
                >
                  <option value="">Seleccionar</option>
                  {moedas.map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.sigla}
                    </option>
                  ))}
                </Input>
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="descricao" className="form-label">
                  Descripción
                </Label>
                <Input
                  id="descricao"
                  className="form-control"
                  type="textarea"
                  placeholder="Descripción"
                  value={descricao}
                  onChange={(e) => {
                    setDescricao(e.target.value);
                  }}
                  maxLength={3000}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <small className="text-info">
              <strong>Nota:</strong> El valor base de un producto será definido
              en esta moneda, pero se podrá cambiar a la moneda elegida al
              momento de generar la orden de pago.
            </small>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => editar(produto)}
              disabled={loading}
            >
              Salvar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalEditar;
