import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";
import { APIClient } from "../../helpers/api_helper";
import ModalApagarConta from "./ModalApagarConta";
import ModalNovaConta from "./ModalNovaConta";

const ContaClienteTable = ({ userId }) => {
  const [contas, setContas] = useState([]);
  const [modalApagar, setModalApagar] = useState(false);
  const [modalNova, setModalNova] = useState(false);
  const [selectedConta, setSelectedConta] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const fetchContas = useCallback(async () => {
    const apiClient = new APIClient();
    const response = await apiClient.get("/contas-cliente", {
      user_id: userId,
    });
    if (response.sucesso) {
      setContas(response.dados);
    } else {
      console.error("Error al buscar cuentas:", response.mensagem);
    }
    setRefresh(false);
  }, [userId]);

  useEffect(() => {
    fetchContas();
  }, [fetchContas, refresh]);

  const handleDeleteClick = (conta) => {
    setSelectedConta(conta);
    setModalApagar(true);
  };

  const handleNovaContaClick = () => {
    setModalNova(true);
  };

  return (
    <Card>
      <CardHeader className="align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Cuentas Bancarias</h4>
        <div className="flex-shrink-0">
          <Button color="soft-secondary" onClick={handleNovaContaClick}>
            Nueva Cuenta
          </Button>
        </div>
      </CardHeader>
      <CardBody className="p-4">
        <div className="live-preview">
          <div className="table-responsive table-card">
            <table className="table align-middle table-nowrap table-striped-columns mb-1">
              <thead className="table-light">
                <tr>
                  <th>Nombre de la Cuenta</th>
                  <th>Destinatario</th>
                  <th>Banco</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {contas.map((conta) => (
                  <tr key={conta.id}>
                    <td>{conta.nome}</td>
                    <td>{conta.nome_destinatario}</td>
                    <td>{conta.banco}</td>
                    <td>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDeleteClick(conta)}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </CardBody>
      <ModalApagarConta
        modal={modalApagar}
        setModal={setModalApagar}
        setRefresh={setRefresh}
        conta={selectedConta}
      />
      <ModalNovaConta
        modal={modalNova}
        setModal={setModalNova}
        userId={userId}
        setRefresh={setRefresh}
      />
    </Card>
  );
};

export default ContaClienteTable;
