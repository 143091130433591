import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { RevenueCharts } from "./DashboardEcommerceCharts";
import CountUp from "react-countup";

const Revenue = () => {
  const [pedidos, setPedidos] = useState(2070);
  const [total, setTotal] = useState(103043);
  const [reembolsos, setReembolsos] = useState(567);
  const [lucro, setLucro] = useState(458.5);
  const [chartData, setchartData] = useState([
    {
      name: "Pedidos",
      type: "area",
      data: [700, 2000, 1500, 2800, 1300, 1000, 3400, 800, 4444, 5889, 3333, 989],
    },
    {
      name: "Total",
      type: "bar",
      data: [700, 2000, 1500, 2800, 1300, 1000, 3400, 800, 4444, 6666, 3333, 989],
    },
    {
      name: "Reembolsos",
      type: "line",
      data: [30, 86, 103, 287, 112, 80, 200, 378, 230, 280, 150, 250],
    },
  ]);

  return (
    <React.Fragment>
      <Col md={6}>
        <Card>
          <CardHeader className="border-0 align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Datos Generales</h4>
            <div className="d-flex gap-1">
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm shadow-none"
                onClick={() => {
                  onChangeChartPeriod("all");
                }}
              >
                Todo
              </button>
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm shadow-none"
                onClick={() => {
                  onChangeChartPeriod("month");
                }}
              >
                1M
              </button>
              <button
                type="button"
                className="btn btn-soft-secondary btn-sm shadow-none"
                onClick={() => {
                  onChangeChartPeriod("halfyear");
                }}
              >
                6M
              </button>
              <button
                type="button"
                className="btn btn-soft-primary btn-sm shadow-none"
                onClick={() => {
                  onChangeChartPeriod("year");
                }}
              >
                1A
              </button>
            </div>
          </CardHeader>

          <CardHeader className="p-0 border-0 bg-light-subtle">
            <Row className="g-0 text-center">
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp start={0} end={pedidos} duration={3} separator="." />
                  </h5>
                  <p className="text-muted mb-0">Pedidos</p>
                </div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp
                      prefix="$ "
                      start={0}
                      decimals={2}
                      end={total}
                      duration={3}
                    />
                  </h5>
                  <p className="text-muted mb-0">Total</p>
                </div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0">
                  <h5 className="mb-1">
                    <CountUp start={0} end={reembolsos} duration={3} />
                  </h5>
                  <p className="text-muted mb-0">Reembolsos</p>
                </div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="p-3 border border-dashed border-start-0 border-end-0">
                  <h5 className="mb-1 text-success">
                    <CountUp
                      start={0}
                      end={lucro}
                      decimals={2}
                      duration={3}
                      suffix="%"
                    />
                  </h5>
                  <p className="text-muted mb-0">Lucro</p>
                </div>
              </Col>
            </Row>
          </CardHeader>

          <CardBody className="p-0 pb-2">
            <div className="w-100">
              <div dir="ltr">
                <RevenueCharts
                  series={chartData}
                  dataColors='["--vz-success", "--vz-primary", "--vz-danger"]'
                />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default Revenue;
