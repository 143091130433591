import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  checkLastLogin,
  formatCurrencyUSD,
  formatCurrency,
  formatCurrencyARS,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
import FeatherIcon from "feather-icons-react";

const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh, pdvId }) => {
  const [loading, setLoading] = useState(false);
  const [calculando, setCalculando] = useState(false);
  const [loadTable, setLoadTable] = useState(false);

  // Lists for dropdowns
  const [pdvs, setPdvs] = useState([]);
  const [atendentes, setAtendentes] = useState([]);
  const [moedas, setMoedas] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [descricao, setDescricao] = useState("");

  // Selected values
  const [pdvSelecionado, setPdvSelecionado] = useState("");
  const [atendenteSelecionado, setAtendenteSelecionado] = useState("");
  const [moedaSelecionada, setMoedaSelecionada] = useState("");
  const [tokenSelecionado, setTokenSelecionado] = useState(null);
  const [produtosSelecionados, setProdutosSelecionados] = useState([]);

  // Search and pagination
  const [termoBusca, setTermoBusca] = useState("");
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [totalDeProdutos, setTotalDeProdutos] = useState(0);
  const itensPorPagina = 5;

  // Calculation result
  const [resumoCalculo, setResumoCalculo] = useState(null);

  const toggle = () => {
    setPdvSelecionado("");
    setAtendentes([]);
    setMoedaSelecionada("");
    setProdutosSelecionados([]);
    setTermoBusca("");
    setResumoCalculo(null);
    setPaginaAtual(1);
    setModal(!modal);
  };

  const fetchPdvs = useCallback(async () => {
    const response = await apiClient.get("/pdvs");
    if (response.sucesso) {
      setPdvs(response.dados || []);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const fetchAtendentes = useCallback(
    async (pdvId) => {
      setPdvSelecionado(pdvId);
      for (const pdv of pdvs) {
        if (pdv.id === parseInt(pdvId || "0")) {
          setAtendentes(pdv.atendentes);
        }
      }
    },
    [pdvs]
  );

  const fetchMoedas = useCallback(async () => {
    const response = await apiClient.get("/moedas");
    if (response.sucesso) {
      setMoedas(response.dados || []);

      const moedaBRL = response.dados.find((m) => m.sigla === "BRL");
      if (moedaBRL) setMoedaSelecionada(moedaBRL.id);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const fetchTokens = useCallback(async () => {
    const response = await apiClient.get("/tokens");
    if (response.sucesso) {
      const tokens = response.dados || [];
      setTokens(tokens);

      // Find USDT token on Arbitrum network
      const usdtArbitrum = tokens.find(
        (token) =>
          token.sigla === "USDT" &&
          token.rede?.nome?.toLowerCase().includes("arbitrum")
      );

      if (usdtArbitrum) {
        setTokenSelecionado(usdtArbitrum);
      } else {
        toast.error("Token USDT en la red Arbitrum no encontrado");
      }
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
  }, []);

  const fetchProdutos = useCallback(async () => {
    setLoadTable(true);
    const data = {
      itens_pagina: itensPorPagina,
      pagina: paginaAtual,
      termo: termoBusca,
      order_by: "created_at",
      order: "desc",
    };

    const response = await apiClient.post("/produtos/busca", data);
    if (response.sucesso) {
      const dados = response.dados;
      setProdutos(dados.data);
      setTotalDeProdutos(dados.total);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoadTable(false);
  }, [paginaAtual, termoBusca]);

  useEffect(() => {
    const fetchAll = async () => {
      await fetchPdvs();
      await fetchMoedas();
      await fetchTokens();
      await fetchProdutos();
    };
    const fetchAt = async (pdvId) => {
      await fetchAtendentes(pdvId);
    };
    if (modal) {
      fetchAll();
    }
    if (pdvId) fetchAt(pdvId);
  }, [modal, paginaAtual, termoBusca, pdvId]);

  const toggleProdutoSelecionado = (produto) => {
    if (produtosSelecionados.find((p) => p.id === produto.id)) {
      setProdutosSelecionados(
        produtosSelecionados.filter((p) => p.id !== produto.id)
      );
    } else {
      setProdutosSelecionados([
        ...produtosSelecionados,
        { ...produto, quantidade: 1 },
      ]);
    }
  };

  const alterarQuantidade = (produtoId, incremento) => {
    setProdutosSelecionados(
      produtosSelecionados.map((produto) => {
        if (produto.id === produtoId) {
          const novaQuantidade = produto.quantidade + incremento;
          return {
            ...produto,
            quantidade: novaQuantidade >= 1 ? novaQuantidade : 1,
          };
        }
        return produto;
      })
    );
  };

  const alterarQuantidadeManual = (produtoId, valor) => {
    setProdutosSelecionados(
      produtosSelecionados.map((produto) => {
        if (produto.id === produtoId) {
          const novaQuantidade = parseInt(valor) || 1;
          return {
            ...produto,
            quantidade: novaQuantidade >= 1 ? novaQuantidade : 1,
          };
        }
        return produto;
      })
    );
  };

  const calcular = async () => {
    if (
      !moedaSelecionada ||
      !tokenSelecionado ||
      produtosSelecionados.length === 0
    ) {
      toast.error("Por favor, seleccione la moneda y al menos un producto");
      return;
    }

    setCalculando(true);
    const data = {
      moeda_id: moedaSelecionada,
      token_id: tokenSelecionado.id,
      produtos: produtosSelecionados.map((p) => ({
        id: p.id,
        quantidade: p.quantidade,
      })),
    };

    const response = await apiClient.post("/links-pagamento/calcular", data);
    if (response.sucesso) {
      setResumoCalculo(response.dados);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setCalculando(false);
  };

  const gerarLink = async () => {
    if (!resumoCalculo) {
      toast.error("Por favor, calcule el valor primero");
      return;
    }

    setLoading(true);
    const data = {
      pdv_id: pdvSelecionado,
      atendente_id: atendenteSelecionado,
      moeda_id: moedaSelecionada,
      token_id: tokenSelecionado.id,
      descricao: descricao,
      produtos: produtosSelecionados.map((p) => ({
        id: p.id,
        quantidade: p.quantidade,
      })),
      valor: resumoCalculo.valor_final,
    };

    const response = await apiClient.post("/links-pagamento", data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      setRefresh(true);
      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      size="xl"
      centered
      className="modal-custom"
    >
      <ModalHeader
        toggle={toggle}
        className="modal-title text-success bg-soft-success p-3"
      >
        Nuevo Link de Pago
      </ModalHeader>
      <ModalBody className="p-4">
        <Row className="g-3">
          <Col md="6">
            <Label className="form-label fw-bold">PDV</Label>
            <Input
              type="select"
              value={pdvSelecionado}
              onChange={(e) => fetchAtendentes(e.target.value)}
              disabled={pdvId ? true : false}
              className="form-select shadow-sm"
            >
              <option value="">Seleccione un PDV</option>
              {pdvs.map((pdv) => (
                <option key={pdv.id} value={pdv.id}>
                  {pdv.nome}
                </option>
              ))}
            </Input>
          </Col>
          <Col md="6">
            <Label className="form-label fw-bold">Atendente</Label>
            <Input
              type="select"
              value={atendenteSelecionado}
              onChange={(e) => setAtendenteSelecionado(e.target.value)}
              required
              className="form-select shadow-sm"
            >
              <option value="">Seleccione un atendente</option>
              {atendentes.map((atendente) => (
                <option key={atendente.id} value={atendente.id}>
                  {atendente.nome}
                </option>
              ))}
            </Input>
          </Col>
        </Row>
        <Row className="mt-4 g-3">
          <Col md="6">
            <Label className="form-label fw-bold">Moneda</Label>
            <Input
              type="select"
              value={moedaSelecionada}
              onChange={(e) => setMoedaSelecionada(e.target.value)}
              required
              className="form-select shadow-sm"
              disabled
            >
              <option value="">Seleccione una moneda</option>
              {moedas.map((moeda) => (
                <option key={moeda.id} value={moeda.id}>
                  {moeda.nome} ({moeda.sigla})
                </option>
              ))}
            </Input>
          </Col>
          <Col md="6">
            <Label className="form-label fw-bold">Token</Label>
            <div className="position-relative">
              <Input
                type="text"
                value={
                  tokenSelecionado
                    ? `${tokenSelecionado.nome} (${tokenSelecionado.sigla}) - ${tokenSelecionado.rede?.nome}`
                    : "Cargando..."
                }
                readOnly
                className="form-control shadow-sm"
              />
              <FeatherIcon
                icon="link"
                size={18}
                className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-4 g-3">
          <Col md="12">
            <Label className="form-label fw-bold">
              Descripción corta (opcional)
            </Label>
            <Input
              type="text"
              placeholder="Descripción corta..."
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
              required
              className="form-control shadow-sm"
              maxLength={255}
            />
          </Col>
        </Row>

        <Row className="mt-4 g-3">
          <Col md="12">
            <Label className="form-label fw-bold">Buscar Productos</Label>
            <div className="position-relative">
              <Input
                type="text"
                placeholder="Digite para buscar productos..."
                value={termoBusca}
                onChange={(e) => {
                  setTermoBusca(e.target.value);
                  setPaginaAtual(1);
                }}
                className="form-control shadow-sm"
              />
              <FeatherIcon
                icon="search"
                size={18}
                className="position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col md="12">
            <div
              className="table-responsive rounded-3"
              style={{
                maxHeight: "300px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                border: "1px solid #e9ecef",
              }}
            >
              {loadTable ? (
                <div className="text-center my-3">
                  <Spinner color="success" size="sm" />
                </div>
              ) : (
                <table className="table table-hover table-striped mb-0">
                  <thead className="table-light sticky-top">
                    <tr>
                      <th style={{ width: "50px" }}></th>
                      <th>Producto</th>
                      <th>Descripción</th>
                      <th>Valor</th>
                      <th>Moneda</th>
                    </tr>
                  </thead>
                  <tbody>
                    {produtos.map((produto) => (
                      <tr
                        key={produto.id}
                        onClick={() => toggleProdutoSelecionado(produto)}
                        style={{
                          cursor: "pointer",
                          transition: "background-color 0.2s ease",
                        }}
                        className={
                          produtosSelecionados.find((p) => p.id === produto.id)
                            ? "table-success"
                            : "hover-highlight"
                        }
                      >
                        <td>
                          <Input
                            type="checkbox"
                            checked={
                              produtosSelecionados.find(
                                (p) => p.id === produto.id
                              )
                                ? true
                                : false
                            }
                            onChange={() => {}}
                          />
                        </td>
                        <td>{produto.nome}</td>
                        <td>{produto.descricao}</td>
                        <td>
                          {produto.moeda?.sigla === "BRL"
                            ? formatCurrency(produto.valor)
                            : produto.moeda?.sigla === "ARS"
                            ? formatCurrencyARS(produto.valor)
                            : formatCurrencyUSD(produto.valor)}
                        </td>
                        <td>{produto.moeda?.sigla}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            {totalDeProdutos > itensPorPagina && (
              <div className="d-flex justify-content-center mt-3 align-items-center">
                <Button
                  color="soft-success"
                  outline
                  onClick={() => setPaginaAtual((p) => Math.max(1, p - 1))}
                  disabled={paginaAtual === 1}
                  size="sm"
                  className="me-2"
                >
                  <FeatherIcon icon="chevron-left" size={14} />
                </Button>
                <div className="text-muted small">
                  Página {paginaAtual} de{" "}
                  {Math.ceil(totalDeProdutos / itensPorPagina)}
                </div>
                <Button
                  color="soft-success"
                  outline
                  onClick={() =>
                    setPaginaAtual((p) =>
                      Math.min(
                        Math.ceil(totalDeProdutos / itensPorPagina),
                        p + 1
                      )
                    )
                  }
                  disabled={
                    paginaAtual >= Math.ceil(totalDeProdutos / itensPorPagina)
                  }
                  size="sm"
                  className="ms-2"
                >
                  <FeatherIcon icon="chevron-right" size={14} />
                </Button>
              </div>
            )}
          </Col>
        </Row>

        {produtosSelecionados.length > 0 && (
          <Row className="mt-4">
            <Col md="12">
              <Card className="shadow-sm border-light">
                <CardBody>
                  <h5 className="card-title mb-3 text-success">
                    <FeatherIcon
                      icon="shopping-cart"
                      size={20}
                      className="me-2"
                    />
                    Productos Seleccionados
                  </h5>
                  <div className="table-responsive">
                    <table className="table table-sm table-borderless">
                      <thead className="table-light">
                        <tr>
                          <th>Producto</th>
                          <th>Valor</th>
                          <th>Moneda</th>
                          <th>Cantidad</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {produtosSelecionados.map((produto) => (
                          <tr key={produto.id} className="align-middle">
                            <td>{produto.nome}</td>
                            <td>
                              {produto.moeda?.sigla === "BRL"
                                ? formatCurrency(produto.valor)
                                : produto.moeda?.sigla === "ARS"
                                ? formatCurrencyARS(produto.valor)
                                : formatCurrencyUSD(produto.valor)}
                            </td>
                            <td>{produto.moeda?.sigla}</td>
                            <td style={{ width: "180px" }}>
                              <div className="d-flex align-items-center gap-2">
                                <div
                                  className="input-group input-group-sm"
                                  style={{ width: "100px" }}
                                >
                                  <Input
                                    type="number"
                                    min="1"
                                    value={produto.quantidade}
                                    onChange={(e) =>
                                      alterarQuantidadeManual(
                                        produto.id,
                                        e.target.value
                                      )
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    className="form-control form-control-sm text-center shadow-sm"
                                  />
                                </div>
                                <div className="hstack gap-1">
                                  <Button
                                    color="soft-success"
                                    size="sm"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      alterarQuantidade(produto.id, -1);
                                    }}
                                    disabled={produto.quantidade <= 1}
                                    outline
                                  >
                                    <FeatherIcon icon="minus" size={14} />
                                  </Button>
                                  <Button
                                    color="soft-success"
                                    size="sm"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      alterarQuantidade(produto.id, 1);
                                    }}
                                    outline
                                  >
                                    <FeatherIcon icon="plus" size={14} />
                                  </Button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {resumoCalculo && (
          <Row className="mt-4">
            <Col md="12">
              <Card className="shadow-sm border-light">
                <CardBody>
                  <h5 className="card-title mb-3 text-success">
                    <FeatherIcon icon="info" size={20} className="me-2" />
                    Resumen del Cálculo *
                  </h5>
                  <span className="text-muted small">
                    (*) Los valores presentados pueden cambiar dependiendo del
                    horario que el cliente genere la orden de compra.
                  </span>
                  <div className="table-responsive mt-2">
                    <table className="table table-sm table-borderless">
                      <tbody>
                        <tr>
                          <th className="text-success">Valor Total:</th>
                          <td className="fw-bold">
                            ~{" "}
                            {resumoCalculo.moeda_sigla === "BRL"
                              ? formatCurrency(resumoCalculo.valor_final)
                              : resumoCalculo.moeda_sigla === "ARS"
                              ? formatCurrencyARS(resumoCalculo.valor_final)
                              : formatCurrencyUSD(resumoCalculo.valor_final)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <div className="hstack gap-2 justify-content-end mt-4">
          <Link
            to="#"
            className="btn btn-soft-success"
            onClick={() => toggle()}
            disabled={loading}
          >
            <i className="ri-close-line me-1 align-middle"></i> Cerrar
          </Link>
          <Button
            color="danger"
            onClick={calcular}
            disabled={
              loading ||
              calculando ||
              !moedaSelecionada ||
              !tokenSelecionado ||
              produtosSelecionados.length === 0
            }
            className="d-flex align-items-center"
          >
            {calculando ? (
              <Spinner size="sm" className="me-2" />
            ) : (
              <FeatherIcon icon="dollar-sign" size={16} className="me-2" />
            )}
            {calculando ? "Calculando..." : "Calcular"}
          </Button>
          {resumoCalculo && (
            <Button
              color="success"
              onClick={() => gerarLink()}
              disabled={loading || calculando || !resumoCalculo}
              className="d-flex align-items-center"
            >
              {loading ? (
                <Spinner size="sm" className="me-2" />
              ) : (
                <FeatherIcon icon="link" size={16} className="me-2" />
              )}
              {loading ? "Generando..." : "Generar Link"}
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
