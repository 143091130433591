import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Row,
  Col,
  FormFeedback,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";
import InputMask from "react-input-mask";

const ModalNovaConta = ({ modal, setModal, userId, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    banco: "",
    agencia: "",
    conta: "",
    pix_alias: "",
    nome_destinatario: "",
    documento: "",
    pais: "Argentina",
    tipo: "fisica",
  });
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    // Clear error when field is modified
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const apiClient = new APIClient();
    const response = await apiClient.post("/contas-cliente", {
      ...formData,
      user_id: userId,
    });

    if (response.sucesso) {
      toast.success("Cuenta bancaria registrada con éxito");
      setRefresh(true);
      setModal(false);
      setFormData({
        nome: "",
        banco: "",
        agencia: "",
        conta: "",
        pix_alias: "",
        nome_destinatario: "",
        documento: "",
        pais: "Argentina",
        tipo: "fisica",
      });
    } else {
      toast.error(response.mensagem || "Error al registrar la cuenta bancaria");
    }

    setLoading(false);
  };

  const isBrazil = formData.pais === "Brasil";
  const isPixProvided = formData.pix_alias?.trim().length > 0;
  const isPessoaFisica = formData.tipo === "fisica";

  const getDocumentoLabel = () => {
    if (isBrazil) {
      return isPessoaFisica ? "CPF" : "CNPJ";
    }
    return isPessoaFisica ? "DNI" : "CUIT";
  };

  const getDocumentoMask = () => {
    if (isBrazil) {
      return isPessoaFisica ? "999.999.999-99" : "99.999.999/9999-99";
    }
    return isPessoaFisica ? "99.999.999" : "99-99999999-9";
  };

  const getDocumentoPlaceholder = () => {
    if (isBrazil) {
      return isPessoaFisica ? "000.000.000-00" : "00.000.000/0000-00";
    }
    return isPessoaFisica ? "00.000.000" : "00-00000000-0";
  };

  return (
    <Modal isOpen={modal} toggle={() => setModal(false)} centered size="lg">
      <ModalHeader toggle={() => setModal(false)}>
        Nueva Cuenta Bancaria
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="pais">País</Label>
                <Input
                  type="select"
                  name="pais"
                  id="pais"
                  value={formData.pais}
                  onChange={handleInputChange}
                >
                  <option value="Argentina">Argentina</option>
                  <option value="Brasil">Brasil</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="tipo">Tipo de Persona</Label>
                <Input
                  type="select"
                  name="tipo"
                  id="tipo"
                  value={formData.tipo}
                  onChange={handleInputChange}
                >
                  <option value="fisica">Persona Física</option>
                  <option value="juridica">Persona Jurídica</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="documento">{getDocumentoLabel()}</Label>
                <InputMask
                  mask={getDocumentoMask()}
                  value={formData.documento}
                  onChange={handleInputChange}
                  className={`form-control ${
                    errors.documento ? "is-invalid" : ""
                  }`}
                  placeholder={getDocumentoPlaceholder()}
                >
                  {(inputProps) => (
                    <Input {...inputProps} type="text" name="documento" />
                  )}
                </InputMask>
                {errors.documento && (
                  <FormFeedback>{errors.documento}</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="nome">Nombre de la Cuenta</Label>
                <Input
                  type="text"
                  name="nome"
                  id="nome"
                  placeholder="Nombre de la cuenta"
                  value={formData.nome}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="nome_destinatario">Nombre del Destinatario</Label>
                <Input
                  type="text"
                  name="nome_destinatario"
                  id="nome_destinatario"
                  placeholder="Nombre completo del destinatario"
                  value={formData.nome_destinatario}
                  onChange={handleInputChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="pix_alias">
                  {isBrazil ? "Chave PIX" : "CBU / CVU / ALIAS"}
                </Label>
                <Input
                  type="text"
                  name="pix_alias"
                  id="pix_alias"
                  placeholder={
                    isBrazil ? "Ingrese la clave PIX" : "Ingrese CBU/CVU/ALIAS"
                  }
                  value={formData.pix_alias}
                  onChange={handleInputChange}
                />
                {isBrazil && (
                  <small className="text-muted">
                    Si informa la clave PIX, agencia, cuenta y banco no son
                    obligatorios
                  </small>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="banco">Banco</Label>
                <Input
                  type="text"
                  name="banco"
                  id="banco"
                  placeholder="Nombre del banco"
                  value={formData.banco}
                  onChange={handleInputChange}
                  required={!isPixProvided}
                />
              </FormGroup>
            </Col>
            {isBrazil && (
              <>
                <Col md={6}>
                  <FormGroup>
                    <Label for="agencia">Agencia</Label>
                    <Input
                      type="text"
                      name="agencia"
                      id="agencia"
                      placeholder="Número de agencia"
                      value={formData.agencia}
                      onChange={handleInputChange}
                      required={!isPixProvided}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="conta">Cuenta</Label>
                    <Input
                      type="text"
                      name="conta"
                      id="conta"
                      placeholder="Número de cuenta"
                      value={formData.conta}
                      onChange={handleInputChange}
                      required={!isPixProvided}
                    />
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>

          <div className="text-end mt-4">
            <Button
              type="button"
              color="light"
              onClick={() => setModal(false)}
              className="me-2"
            >
              Cancelar
            </Button>
            <Button type="submit" color="primary" disabled={loading}>
              {loading ? <Spinner size="sm" /> : "Registrar"}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovaConta;
