import React, { useState, useCallback, useEffect } from "react";
import Calculadora from "../GerarOrdemPix/Calculadora";
import { Link } from "react-router-dom";
import { APIClient, } from "../../helpers/api_helper";
const apiClient = new APIClient();
import { toast } from "react-toastify";

import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  CardHeader,
  Spinner,
} from "reactstrap";

const Pdv = ({ pdv_id }) => {
  const [pdvDados, setPdvDados] = useState(null);
  const [loading, setLoading] = useState(false);
  const [atendenteId, setAtendenteId] = useState(null);
  const [pinAceito, setPinAceito] = useState(false);
  const [pin, setPin] = useState("");

  const fetchPdvDados = useCallback(async (pdvId) => {
    setLoading(true);
    const params = {
      pdv_id: pdvId,
    };
    const response = await apiClient.get("/pdvs/ver", params);
    if (response.sucesso) {
      const dados = response.dados;
      setPdvDados(dados);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  }, []);

  const checkPin = async (pdvId, pinToCheck) => {
    setLoading(true);

    const data = {
      pin: pinToCheck,
      pdv_id: pdvId,
    };

    const response = await apiClient.post(`/pdvs/check-pin`, data);
    if (response.sucesso) {
      setPinAceito(true);
      // If no attendants, set atendenteId to 0
      if (!pdvDados?.atendentes?.length) {
        setAtendenteId(0);
      }
    } else {
      if (response.mensagem !== "") toast.error(response.mensagem);
      setPin("");
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  useEffect(() => {
    const fetchDados = async (pdv_id) => {
      await fetchPdvDados(pdv_id);
    };
    if(pdv_id) fetchDados(pdv_id);
  }, [fetchPdvDados]);

  // Handle PIN input and validation
  const handlePinChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 4);
    setPin(value);
    
    if (value.length === 4) {
      checkPin(pdv_id, value);
    }
  };

  const renderAtendentesSelection = () => {
    return (
      <Col xs={12}>
        <div className="text-center mb-4">
          <h4>Seleccione el Vendedor</h4>
        </div>
        <Row className="g-3 justify-content-center">
          <Col xs={8}>
            <Card 
              className="cursor-pointer card-light"
              onClick={() => setAtendenteId(0)}
              style={{ 
                cursor: 'pointer',
                transition: 'transform 0.2s',
              }}
              onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
              onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <CardBody className="text-center">
                <h5 className="card-title mb-0">Sin Vendedor</h5>
              </CardBody>
            </Card>
          </Col>
          {pdvDados?.atendentes?.map((atendente) => (
            <Col xs={8} key={atendente.id}>
              <Card 
              className="cursor-pointer card-light"
                onClick={() => setAtendenteId(atendente.id)}
                style={{ 
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                }}
                onMouseOver={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}
              >
                <CardBody className="text-center">
                  <h5 className="card-title mb-0">{atendente.nome}</h5>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Col>
    );
  };

  document.title = "Punto de Venda | Nautt Finance";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col xl={8} lg={8} md={10} sm={12}>
              <Card className="product">
                <CardBody>
                  <Row className="gy-3">
                    {!pinAceito ? (
                      <Col xs={12}>
                        <div className="text-center" style={{ marginTop: '100px', marginBottom: '100px' }}>
                          {loading ? (
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                          ) : (
                            <div>
                              <h4 className="mb-4">Ingrese el PIN del PDV</h4>
                              <Input
                                type="password"
                                value={pin}
                                onChange={handlePinChange}
                                className="form-control form-control-lg text-center"
                                style={{
                                  maxWidth: '200px',
                                  margin: '0 auto',
                                  fontSize: '24px',
                                  letterSpacing: '0.5em'
                                }}
                                placeholder="****"
                                autoFocus
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    ) : atendenteId === null && pdvDados?.atendentes?.length > 0 ? (
                      renderAtendentesSelection()
                    ) : (
                      <Calculadora pdv_id={pdv_id} atendente_id={atendenteId} />
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pdv;
