import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";

const ModalSaque = ({ modal, toggle }) => {
  const [destino, setDestino] = useState("Banco");
  const [moeda, setMoeda] = useState("Real Brasileño (BRL)");

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Retirar fondos</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="destino">Destino</Label>
          <Input
            type="select"
            name="destino"
            id="destino"
            value={destino}
            onChange={(e) => {
              setDestino(e.target.value);
              if (e.target.value === "Wallet Cripto") {
                setMoeda("Dólar Estadounidense (USD)");
              }
            }}
          >
            <option>Banco</option>
            <option>Wallet Cripto</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="moeda">Moeda de saque</Label>
          <Input
            type="select"
            name="moeda"
            id="moeda"
            value={moeda}
            onChange={(e) => setMoeda(e.target.value)}
            disabled={destino === "Wallet Cripto"}
          >
            <option>Real Brasileño (BRL)</option>
            <option>Peso Argentino (ARS)</option>
            <option>Dólar Estadounidense (USD)</option>
          </Input>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="warning" disabled>
          En Mantenimiento
        </Button>
        <Link
          to="#"
          className="btn btn-link link-warning fw-medium"
          onClick={() => toggle()}
        >
          <i className="ri-close-line me-1 align-middle"></i> Fechar
        </Link>
      </ModalFooter>
    </Modal>
  );
};

export default ModalSaque;
