import axios from "axios";

// default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = false;

// Função para adicionar atraso
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Interceptor para adicionar atraso entre requisições
axios.interceptors.request.use(async (config) => {
  await delay(100); // Adiciona um atraso de 500ms entre as requisições
  return config;
});

const getLoggedinUser = async () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const contemErrorCode = (str) => {
  return (
    str.includes(" 401") ||
    str === "Request failed with status code 401" ||
    str.includes("Unauthenticated")
  );
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = async (url, params) => {
    const authUser = await getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.get(url, { params, headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  /**
   * post given data to url
   */
  post = async (url, data) => {
    const formEncodedData = new URLSearchParams();
    for (const key in data) {
      formEncodedData.append(key, data[key]);
    }

    const authUser = await getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.post(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      console.error(mensagemErro);
      return {
        mensagem: contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  /**
   * post for download endpoints
   */
  postDownload = async (url, data) => {
    const authUser = await getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.post(url, data, { 
        headers,
        responseType: 'blob'
      });
      
      return {
        sucesso: true,
        responseCode: response.status,
        dados: response.data,
        headers: response.headers,
      };
    } catch (error) {
      console.error(error);
      return {
        sucesso: false,
        responseCode: error.response?.status || 500,
        mensagem: "Erro ao baixar o arquivo.",
      };
    }
  };

  /**
   * post given data to url
   */
  postLogin = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        data
      );
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      console.error(mensagemErro);
      return {
        mensagem: contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: data,
      };
    }
  };

  /**
   * Updates data
   */
  update = async (url, data) => {
    const authUser = await getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.patch(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  put = async (url, data) => {
    const authUser = await getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.put(url, data, { headers });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };

  /**
   * Delete
   */
  delete = async (url, params, config={}) => {
    const authUser = await getLoggedinUser();
    const headers = {
      Authorization: `Bearer ${authUser?.token}`,
    };

    try {
      const response = await axios.delete(url, { ...config, headers, params });
      const { mensagem, dados } = response.data;
      return {
        mensagem,
        sucesso: true,
        responseCode: response.status,
        dados,
      };
    } catch (error) {
      const mensagemErro =
        error.response?.data?.mensagem ||
        error.response?.data?.message ||
        error.response?.mensagem ||
        error.response?.message ||
        error.message ||
        error.mensagem ||
        "Erro desconhecido.";
      return {
        mensagem: contemErrorCode(mensagemErro) ? "" : mensagemErro,
        sucesso: false,
        responseCode: contemErrorCode(mensagemErro)
          ? 401
          : error.response?.status
          ? error.response.status
          : 403,
        dados: null,
      };
    }
  };
}

export { APIClient, getLoggedinUser };
