import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  UncontrolledAlert,
  Spinner,
  Button,
  CardText,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import QRCode from "qrcode.react";
import FeatherIcon from "feather-icons-react";
import {
  formatCurrencyUSD,
  formatCurrency,
  formatCurrencyARS,
  checkLastLogin,
} from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";

import { changeLayoutMode } from "../../slices/thunks";
import { layoutModeTypes } from "../../Components/constants/layout";

import logoSm1 from "../../assets/images/logo-sm-1.png";

const apiClient = new APIClient();

const CheckoutProdutos = ({ link_id }) => {
  const [loading, setLoading] = useState(true);
  const [calculando, setCalculando] = useState(false);
  const [linkData, setLinkData] = useState(null);
  const [resumoCalculo, setResumoCalculo] = useState(null);
  const [qrCode, setQrCode] = useState("");
  const [ordem, setOrdem] = useState(null);
  const [countdown, setCountdown] = useState(0);

  const dispatch = useDispatch();

  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  const fetchLinkData = async () => {
    setLoading(true);
    const response = await apiClient.get(`/links-pagamento/exibir`, {
      link_id: link_id,
    });
    if (response.sucesso) {
      if (response.dados.ordem) {
        const remainingTime = calcularDiferenca(
          response.dados.ordem.created_at
        );
        if (remainingTime > 0) {
          setOrdem(response.dados.ordem);
          setQrCode(response.dados.ordem.qrcode);
          startCountdown(calcularDiferenca(response.dados.ordem.created_at));
        }
      }
      setLinkData(response.dados);
      await calcularValor(response.dados);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setLoading(false);
  };

  const calcularDiferenca = (dataBanco) => {
    // Convert the database timestamp (which is in Brasilia time) to a Date object
    const dbDateBrasilia = new Date(dataBanco);
    
    // Get current time in UTC
    const now = new Date();
    
    // Convert both dates to Brasilia time by adjusting for the -3 offset
    const brasiliaOffset = -3 * 60; // -3 hours in minutes
    const nowBrasilia = new Date(now.getTime() - (now.getTimezoneOffset() + brasiliaOffset) * 60000);
    
    // Calculate difference in seconds
    const diffInSeconds = Math.floor((nowBrasilia - dbDateBrasilia) / 1000);
    
    // Return remaining time (10 minutes = 600 seconds)
    return Math.max(600 - diffInSeconds, 0);
  };

  const calcularValor = async (data) => {
    setCalculando(true);
    const calcData = {
      moeda_id: data.moeda_id,
      token_id: data.token_id,
      produtos: data.produtos.map((p) => ({
        id: p.id,
        quantidade: p.pivot.quantidade,
      })),
    };

    const response = await apiClient.post(
      "/links-pagamento/calcular",
      calcData
    );
    if (response.sucesso) {
      let dadosResponse = response.dados;
      const remainingTime = calcularDiferenca(response.dados.created_at);
      if (remainingTime > 0) {
        dadosResponse = {
          ...dadosResponse,
          valor_final: ordem.total_brl * (1 - remainingTime / 600),
        };
      }

      setResumoCalculo(dadosResponse);
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    setCalculando(false);
  };

  const formatPrice = (price, sigla) => {
    switch (sigla) {
      case "BRL":
        return formatCurrency(price);
      case "ARS":
        return formatCurrencyARS(price);
      default:
        return formatCurrencyUSD(price);
    }
  };

  const handleGerarQrCode = async () => {
    setLoading(true);

    const data = {
      link_id: linkData.id,
      moeda_final: "USDT",
      pdv_id: linkData.pdv_id === 0 ? null : linkData.pdv_id,
      total_brl: resumoCalculo.valor_final,
      expiracao: 10 * 60,
    };

    const response = await apiClient.post("/gerar-qr", data);
    if (response.sucesso) {
      toast.success("Código QR generado con éxito");
      setQrCode(response.dados.qrcode);
      setOrdem(response.dados);
      startCountdown(10 * 60);
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
    setLoading(false);
  };

  const startCountdown = (seconds) => {
    setCountdown(seconds);
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  };

  const refreshOrdemData = async (ordemData) => {
    const params = {
      ordem_id: ordemData.id,
    };
    const response = await apiClient.get(`/ordem`, params);
    if (response.sucesso) {
      if(response.dados.status !== "novo") {
        setOrdem(null);
        let dadosLink = linkData;
        dadosLink.ordem = null;
        setLinkData(dadosLink);
        setQrCode("");
        return;
      }
      const remainingTime = calcularDiferenca(response.dados.created_at);
      console.log("Remaining time:", remainingTime);
      if (remainingTime > 0) {
        setOrdem(response.dados);
        console.log("Ordem atualizada:", response.dados);
      } else {
        setOrdem(null);
        setQrCode("");
        let dadosLink = linkData;
        dadosLink.ordem = null;
        setLinkData(dadosLink);
      }
    } else {
      console.error(response.mensagem);
      if (!response.mensagem.includes(" 401"))
        if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }
  };

  useEffect(() => {
    const fetchAll = async () => {
      onChangeLayoutMode(layoutModeTypes.LIGHTMODE);
      await fetchLinkData();
    };
    if (link_id) {
      fetchAll();
    }
  }, [link_id]);

  useEffect(() => {
    let interval;
    if (qrCode !== "") {
      interval = setInterval(() => {
        refreshOrdemData(ordem);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [qrCode, ordem]);

  document.title = "Check-Nautt | Nautt Finance";

  if (loading || !linkData) {
    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center mt-4 pt-2 text-center">
            <Spinner color="primary" />
          </Row>
        </Container>
      </div>
    );
  }

  const renderQRCodeSection = () => {
    if (ordem && ordem.status !== "novo") {
      return (
        <CardBody>
          <div
            className={`rounded-circle p-3 mb-2 ${
              ordem.status === "pago" ? "bg-success" : "bg-danger"
            }`}
            style={{ width: "100px", height: "100px", margin: "0 auto" }}
          >
            <FeatherIcon
              icon={ordem.status === "pago" ? "check-circle" : "x-circle"}
              className="text-white"
              size={70}
            />
          </div>
          <CardText
            tag="h4"
            className={`font-weight-bold mt-4 text-${
              ordem.status === "pago" ? "success" : "danger"
            }`}
          >
            {ordem.status === "pago" ? "Pago Recibido" : "Orden Inválida"}
          </CardText>
          <CardText>
            {ordem.status === "pago"
              ? "El pago se ha procesado con éxito."
              : "La orden se consideró inválida. Por favor, inténtelo de nuevo."}
          </CardText>
        </CardBody>
      );
    }

    return (
      <CardBody>
        {qrCode !== "" && countdown > 0 ? (
          <>
            <Row className="mt-4 d-flex justify-content-center">
              <Col
                xs="12"
                sm="10"
                md="8"
                className="d-flex justify-content-center"
              >
                {qrCode?.includes(".png") ? (
                  <img
                    src={qrCode}
                    width={220}
                    alt="QR Code"
                    style={{ backgroundColor: "white" }}
                    className="p-2"
                  />
                ) : (
                  <QRCode
                    value={qrCode}
                    size={220}
                    style={{ backgroundColor: "white" }}
                    className="p-2"
                  />
                )}
              </Col>
            </Row>
            <div className="text-center mt-3">
              <h5>
                Tiempo restante: {Math.floor(countdown / 60)}:
                {countdown % 60 < 10 ? "0" : ""}
                {countdown % 60}
              </h5>
            </div>
          </>
        ) : null}
      </CardBody>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="mb-3">
            <Col xl={8}>
              {linkData.produtos.map((produto, index) => (
                <React.Fragment key={produto.id}>
                  <Card className="product">
                    <CardBody>
                      <Row className="gy-3">
                        <div className="col-sm-auto">
                          <div className="avatar-lg bg-light rounded p-1">
                            <img
                              src={logoSm1}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </div>
                        </div>
                        <div className="col-sm">
                          <h5 className="fs-14 text-truncate">
                            <Link to="#" className="text-body">
                              {produto.nome}
                            </Link>
                          </h5>
                          <ul className="list-inline text-muted">
                            <li className="list-inline-item">
                              Descripción:{" "}
                              <span className="fw-medium">
                                {produto.descricao}
                              </span>
                            </li>
                          </ul>

                          <div className="text-muted">
                            Cantidad: {produto.pivot.quantidade}
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div className="text-lg-end">
                            <p className="text-muted mb-1">
                              Precio unitario (USD):
                            </p>
                            <h5 className="fs-14">
                              {resumoCalculo?.produtos &&
                                formatCurrencyUSD(
                                  resumoCalculo.produtos[index]?.preco_usd || 0
                                )}
                            </h5>
                          </div>
                        </div>
                      </Row>
                    </CardBody>

                    <div className="card-footer">
                      <div className="row align-items-center gy-3">
                        <div className="col-sm">
                          <div className="d-flex flex-wrap my-n1">
                            <div className="text-muted">
                              {resumoCalculo?.moeda_sigla}
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-auto">
                          <div className="d-flex align-items-center gap-2 text-muted">
                            <div>Total :</div>
                            <h5 className="fs-14 mb-0">
                              {resumoCalculo?.produtos &&
                                resumoCalculo.cotacao &&
                                formatPrice(
                                  resumoCalculo.produtos[index]?.preco_usd *
                                    produto.pivot.quantidade *
                                    resumoCalculo.cotacao,
                                  resumoCalculo.moeda_sigla
                                )}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </React.Fragment>
              ))}
            </Col>

            <Col xl={4}>
              <div className="sticky-side-div">
                <Card>
                  <CardHeader className="border-bottom-dashed">
                    <h5 className="card-title mb-0">Finalizar Compra</h5>
                  </CardHeader>
                  <CardHeader className="bg-light-subtle border-bottom-dashed">
                    <div className="text-center">
                      {qrCode === "" || countdown === 0 ? (
                        <Button
                          color="primary"
                          className="w-xs"
                          onClick={handleGerarQrCode}
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner size="sm" />
                          ) : (
                            "Generar Código QR"
                          )}
                        </Button>
                      ) : (
                        <Button
                          color="light"
                          className="w-xs"
                          onClick={() => {
                            navigator.clipboard.writeText(qrCode);
                            toast.success("Código QR copiado al portapapeles");
                          }}
                        >
                          <FeatherIcon icon="copy" size={20} /> Copiar Código QR
                        </Button>
                      )}
                    </div>
                  </CardHeader>
                  {renderQRCodeSection()}
                  <CardBody className="pt-2">
                    <div className="table-responsive">
                      <table className="table table-borderless mb-0">
                        <tbody>
                          {resumoCalculo && (
                            <>
                              <tr>
                                <td>Subtotal :</td>
                                <td className="text-end">
                                  {formatPrice(
                                    resumoCalculo.produtos.reduce(
                                      (total, produto, index) =>
                                        total +
                                        produto.preco_usd *
                                          linkData.produtos[index].pivot
                                            .quantidade *
                                          resumoCalculo.cotacao,
                                      0
                                    ),
                                    resumoCalculo.moeda_sigla,
                                    true
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Tasas :</td>
                                <td className="text-end">
                                  {formatPrice(
                                    resumoCalculo.valor_final -
                                      resumoCalculo.produtos.reduce(
                                        (total, produto, index) =>
                                          total +
                                          produto.preco_usd *
                                            linkData.produtos[index].pivot
                                              .quantidade *
                                            resumoCalculo.cotacao,
                                        0
                                      ),
                                    resumoCalculo.moeda_sigla,
                                    true
                                  )}
                                </td>
                              </tr>
                              <tr className="table-active">
                                <th>Total ({resumoCalculo.moeda_sigla}) :</th>
                                <td className="text-end">
                                  <span className="fw-semibold">
                                    {formatPrice(
                                      resumoCalculo.valor_final,
                                      resumoCalculo.moeda_sigla
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>

                <UncontrolledAlert color="success" className="border-dashed">
                  <div className="d-flex align-items-center">
                    <div className="ms-2">
                      <h5 className="fs-14 text-success fw-semibold">
                        ¿Quieres una página de cobro como esta?
                      </h5>
                      <p className="text-success mb-1">
                        Contacta con Nautt Finance para realizar un onboarding
                        gratuito y comenzar a recibir pagos en línea.
                      </p>
                      <a
                        href="https://nautt.finance"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn p-2 btn-sm btn-link text-success text-uppercase"
                      >
                        Comenzar Ahora
                      </a>
                    </div>
                  </div>
                </UncontrolledAlert>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CheckoutProdutos;
