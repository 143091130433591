import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { checkLastLogin } from "../../Components/FunctionsRepository";
import { APIClient } from "../../helpers/api_helper";
const apiClient = new APIClient();

const ModalNovo = ({ modal, setModal, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [nome, setNome] = useState("");
  const [pin, setPin] = useState("");
  const [observacao, setObservacao] = useState("");

  const toggle = () => {
    setNome("");
    setPin("");
    setObservacao("");
    setModal(!modal);
  };

  const criar = async () => {
    setLoading(true);

    const data = {
      nome,
      pin,
      observacao,
    };

    const response = await apiClient.post(`/pdvs`, data);
    if (response.sucesso) {
      toast.success(response.mensagem);
      localStorage.removeItem("lastUpdated");
      setRefresh(true);

      toggle();
    } else {
      console.error(response.mensagem);
      if (response.mensagem !== "") toast.error(response.mensagem);
    }
    if (
      !response.sucesso &&
      (!response.responseCode || response.responseCode === 401)
    ) {
      await checkLastLogin();
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      className="modal-lg"
      centered
    >
      <ModalHeader
        className="modal-title"
        toggle={() => {
          toggle();
        }}
      >
        Nuevo PDV
      </ModalHeader>
      <ModalBody className="text-left p-5">
        <div className="mt-4">
          <Row className="mt-3">
            <Col md="6">
              <div>
                <Label htmlFor="nome" className="form-label">
                  Nombre
                </Label>
                <Input
                  id="nome"
                  className="form-control"
                  type="text"
                  placeholder="Nombre"
                  value={nome}
                  onChange={(e) => {
                    setNome(e.target.value);
                  }}
                  maxLength={50}
                />
              </div>
            </Col>
            <Col md="6">
              <div>
                <Label htmlFor="pin" className="form-label">
                  Pin
                </Label>
                <Input
                  id="pin"
                  className="form-control"
                  type="text"
                  placeholder="Pin"
                  value={pin}
                  onChange={(e) => {
                    setPin(e.target.value.replace(/\D/g, ""));
                  }}
                  maxLength={4}
                />
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col md="12">
              <div>
                <Label htmlFor="observacao" className="form-label">
                  Observação
                </Label>
                <Input
                  id="observacao"
                  className="form-control"
                  type="textarea"
                  placeholder="Observação"
                  value={observacao}
                  onChange={(e) => {
                    setObservacao(e.target.value);
                  }}
                  maxLength={3000}
                />
              </div>
            </Col>
          </Row>

          <div className="hstack gap-2 justify-content-end mt-5">
            <Link
              to="#"
              className="btn btn-link link-primary fw-medium"
              onClick={() => toggle()}
              disabled={loading}
            >
              <i className="ri-close-line me-1 align-middle"></i> Cerrar
            </Link>
            <button
              className="btn btn-primary"
              onClick={() => criar()}
              disabled={loading}
            >
              Criar
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovo;
