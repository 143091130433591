import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";
import { APIClient } from "../../helpers/api_helper";

const apiClient = new APIClient();

const ModalNovaCarteira = ({ modal, setModal, userId, setRefresh }) => {
  const [loading, setLoading] = useState(false);
  const [carteira, setCarteira] = useState("");
  const [nome, setNome] = useState("");
  const [redeId, setRedeId] = useState("");
  const [redes, setRedes] = useState([]);

  useEffect(() => {
    const fetchRedes = async () => {
      const response = await apiClient.get("/redes");
      if (response.sucesso) {
        setRedes(response.dados);
      } else {
        console.error("Error al buscar redes:", response.mensagem);
        toast.error("Error al cargar redes disponibles");
      }
    };

    fetchRedes();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const response = await apiClient.post("/carteira-cliente", {
      user_id: userId,
      nome: nome,
      carteira: carteira,
      tipo: "comum",
      rede_id: redeId,
    });

    setLoading(false);

    if (response.sucesso) {
      toast.success(response.mensagem || "Wallet añadida con éxito");
      setRefresh((prev) => !prev);
      toggle();
    } else {
      console.error(response.mensagem);
      toast.error(response.mensagem || "Error al añadir wallet");
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Nueva Wallet</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="nome">Nombre</Label>
            <Input
              type="text"
              name="nome"
              id="nome"
              placeholder="Nombre"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="carteira">Dirección de la Wallet</Label>
            <Input
              type="text"
              name="carteira"
              id="carteira"
              placeholder="Dirección de la wallet"
              value={carteira}
              onChange={(e) => setCarteira(e.target.value)}
              maxLength={42}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="rede">Red</Label>
            <Input
              type="select"
              name="rede"
              id="rede"
              value={redeId}
              onChange={(e) => setRedeId(e.target.value)}
              required
            >
              <option value="">Seleccione una red</option>
              {redes.map((rede) => (
                <option key={rede.id} value={rede.id}>
                  {rede.nome}
                </option>
              ))}
            </Input>
          </FormGroup>
          <Button color="primary" type="submit" disabled={loading}>
            {loading ? "Añadiendo..." : "Añadir Wallet"}
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ModalNovaCarteira;
